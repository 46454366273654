import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Login = lazy(() => import('./user-pages/Login'));
const ForgotPassword = lazy(() => import('./forgot-password/ForgotPassword'));
// const Register1 = lazy(() => import('./user-pages/Register'));
const Courses = lazy(() => import('./courses/Courses'));
const Course = lazy(() => import('./courses/Course'));
const CoursesSequence = lazy(() => import('./courses/CoursesSequence'));
const Lessons = lazy(() => import('./lessons/Lessons'));
const LessonSequence = lazy(() => import('./lessons/LessonSequence'));
const CategorySequence = lazy(() => import('./lessons/CategorySequence'));
const LiveSessions = lazy(() => import('./live-sessions/LiveSessions'));
const Meetings = lazy(() => import('./meetings/Meetings'));
const Users = lazy(() => import('./users/Users'));
const User = lazy(() => import('./users/User'));
const SubscribedUsers = lazy(() => import('./users/SubscribedUsers'));
const ActiveSubscribedUsers = lazy(() => import('./users/ActiveSubscribedUsers'));
const Packages = lazy(() => import('./packages/Packages'));
const Posts = lazy(() => import('./community/Posts'));
const InspirationPosts = lazy(() => import('./community/InspirationPosts'));
const ReportedPosts = lazy(() => import('./community/ReportedPosts'));
const ReportedComments = lazy(() => import('./community/ReportedComments'));
const Categories = lazy(() => import('./lessons/Categories'));
const EmailTemplate = lazy(() => import('./email-template/EmailTemplate'));
const isloggedIn = window.localStorage.getItem("loggedin")


class AppRoutes extends Component {
  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch >
          <Route  exact path={isloggedIn ? "/dashboard": "users/login"} component={ Dashboard } />


          <Route path="/admin/login" component={ Login } />
          <Route path="/forgot-password" component={ ForgotPassword } />
          {/* <Route path="/admin/register-1" component={ Register1 } /> */}
          <Route path={isloggedIn ? "/courses" : "users/login"} component={ Courses } />
          <Route path={isloggedIn ? "/courses-sequence" : "users/login"} component={ CoursesSequence } />
          <Route path={isloggedIn ? "/course/:id" : "users/login"} component={ Course } />
          <Route path={isloggedIn ? "/lessons" : "users/login"} component={ Lessons } />
          <Route path={isloggedIn ? "/lessons-sequence" : "users/login"} component={ LessonSequence } />
          <Route path={isloggedIn ? "/category-sequence" : "users/login"} component={ CategorySequence } />
          <Route path={isloggedIn ? "/categories" : "users/login"}  component={ Categories }/>
          <Route path={isloggedIn ? "/live-sessions" : "users/login"} component={ LiveSessions } />
          <Route path={isloggedIn ? "/private-session" : "users/login"} component={ Meetings } />
          <Route path={isloggedIn ? "/users-list" : "users/login"} component={ Users } />
          <Route path={isloggedIn ? "/user/:id" : "users/login"} component={ User } />
          <Route path={isloggedIn ? "/subscribed-users-list" : "users/login"} component={ SubscribedUsers } />
          <Route path={isloggedIn ? "/packages" : "users/login"} component={ Packages } />
          <Route path={isloggedIn ? "/community/all-posts" : "users/login"} component={ Posts } />
          <Route path={isloggedIn ? "/community/reported-posts" : "users/login"} component={ ReportedPosts } />
          <Route path={isloggedIn ? "/community/reported-comments" : "users/login"} component={ ReportedComments } />
          <Route path={isloggedIn ? "/community/inspiration-posts" : "users/login"} component={ InspirationPosts } />
          <Route path={isloggedIn ? "/email-template" : "users/login"} component={ EmailTemplate } />
          <Route path={isloggedIn ? "/active-subscribed-users" : "users/login"} component={ ActiveSubscribedUsers } />


          <Redirect to="/admin/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;